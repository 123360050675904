// Import du CSS

import "./CardSectionFusion.css";

// Import des images

import Language from "../Image/LanguageCourses/Language.png";
import French from "../Image/LanguageCourses/French.png";
import Spanish from "../Image/LanguageCourses/Spanish.png";
import Italian from "../Image/LanguageCourses/Italian.png";
import Portuguese from "../Image/LanguageCourses/Portuguese.png";
import Intercomprehension from "../Image/LanguageCourses/Intercomprehension.png";
import Romanian from "../Image/LanguageCourses/Romanian.png";
import ComputerScience from "../Image/SpecialityCourses/ComputerScience.png";
import Courses from "../Image/SpecialityCourses/Courses.png";
import Economics from "../Image/SpecialityCourses/Economics.png";
import FoodScience from "../Image/SpecialityCourses/FoodScience.png";
import Geoscience from "../Image/SpecialityCourses/Geoscience.png";
import Languages from "../Image/SpecialityCourses/Languages.png";
import Law from "../Image/SpecialityCourses/Law.png";
import Literature from "../Image/SpecialityCourses/Literature.png";
import Management from "../Image/SpecialityCourses/Management.png";
import Tourism from "../Image/SpecialityCourses/Tourism.png";
import AnimalScience from "../Image/SpecialityCourses/AnimalScience.png";
import Engineering from "../Image/SpecialityCourses/Engineering.png";
import Mathematics from "../Image/SpecialityCourses/Mathematics.png";
import PoliticalScience from "../Image/SpecialityCourses/PoliticalScience.png";
import Psychology from "../Image/SpecialityCourses/Psychology.png";
import Sports from "../Image/SpecialityCourses/sports.png";
import A1 from "../Image/Labels/A1.png";
import A2 from "../Image/Labels/A2.png";
import A1p from "../Image/Labels/A1p.png";
import A2p from "../Image/Labels/A2p.png";
import B1 from "../Image/Labels/B1.png";
import B2 from "../Image/Labels/B2.png";
import B2Italiano from "../Image/Labels/B2 italiano.png";
import B1p from "../Image/Labels/B1p.png";
import B2p from "../Image/Labels/B2p.png";
import C1 from "../Image/Labels/C1.png";
import C2 from "../Image/Labels/C2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Semestre1 from "../Image/Labels/Semestre 1.png";
import Semestre2 from "../Image/Labels/Semestre 2.png";
import Annual from "../Image/Labels/annual.png";

// Import des fonctions

import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Button,
  Container,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardFooter,
  Tooltip,
} from "reactstrap";
//A supprimer
import ReactGA from "react-ga4";

function DisplayCatalog() {
  //Data first
  useEffect(() => {
    const fetchLanguageData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL + "/api/GetLanguageCourses"
        );
        setResponseLanguageData(response.data);
        setFilteredLanguageData(response.data);
      } catch (error) {
        // Handle error if necessary
        console.log(error);
      }
    };

    fetchLanguageData();
  }, []);

  useEffect(() => {
    const fetchSpecialtyData = async () => {
      try {
        //
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL + "/getCourses"
        );
        setResponseSpecialtyData(response.data);
        setFilteredSpecialtyData(response.data);
      } catch (error) {
        // Handle error if necessary
      }
    };

    fetchSpecialtyData();
  }, []);

  //Affectation des images
  const getImageByName = (name) => {
    const imageMap = {
      A1: A1,
      A2: A2,
      A1p: A1p,
      A2p: A2p,
      B1: B1,
      B2: B2,
      B1p: B1p,
      B2p: B2p,
      C1: C1,
      C2: C2,
      S1: Semestre1,
      S2: Semestre2,
      Annual: Annual,
      B2Italiano: B2Italiano,
      "B - Italiano per lo studio": B2Italiano,
      ComputerScience: ComputerScience,
      "Computer Science": ComputerScience,
      "Food Science": FoodScience,
      FoodScience: FoodScience,
      Courses: Courses,
      Economics: Economics,
      Geoscience: Geoscience,
      Languages: Languages,
      Langages: Languages,
      Law: Law,
      Literature: Literature,
      Management: Management,
      Tourism: Tourism,
      AnimalScience: AnimalScience,
      "Animal Science": AnimalScience,
      "Agriculture, forestry, fisheries and veterinary": AnimalScience,
      Engineering: Engineering,
      Mathematics: Mathematics,
      PoliticalScience: PoliticalScience,
      Psychology: Psychology,
      Sports: Sports,
    };
    return imageMap[name] || null;
  };

  //Affectation des états
  const [selectedSemester, setSelectedSemester] = useState("All semesters");
  const [selectedLevel, setSelectedLevel] = useState("All levels");
  const [selectedGrade, setSelectedGrade] = useState("All grades");
  const [selectedFormat, setSelectedFormat] = useState("All formats");
  const [selectedInstitution, setSelectedInstitution] =
    useState("All Universities");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const tableRef = useRef(null);
  const [selectedLanItem, setSelectedLanItem] = useState(null);
  const [selectedSpeItem, setSelectedSpeItem] = useState(null);
  const [modals, setModals] = useState([]);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const [specialtyOpen, setSpecialtyOpen] = useState(false);
  const [TooltipOpen, setTooltipOpen] = useState(false);
  const [responseLanguageData, setResponseLanguageData] = useState([]);
  const [responseSpecialtyData, setResponseSpecialtyData] = useState([]);
  const [filteredLanguageData, setFilteredLanguageData] = useState([]);
  const [filteredSpecialtyData, setFilteredSpecialtyData] = useState([]);

  // const tableRef = useRef(null);
  // const [selectedItem, setSelectedItem] = useState(null);
  // const [selectedSemester, setSelectedSemester] = useState("Select a semester");

  // const [selectedLevel, setSelectedLevel] = useState("Select a level");

  const checkSelectLanguage = (content) => {
    if (content === selectedLanguage) {
      content = "";
    }
    refreshLanguage(content);
  };

  const refreshLanguage = (content) => {
    //A SUPPRIMER
    ReactGA.event({
      category: content,
      action: "Clicked the button " + content,
    });

    setSelectedLanguage(content);
    setSelectedLanItem(content);
    setIsOpen2(true);
    filterLanguageData(
      content,
      selectedSemester,
      selectedLevel,
      selectedFormat
    );
  };

  useEffect(() => {
    refreshLanguage(selectedLanguage);
  }, [selectedSemester, selectedLanguage, selectedLevel, selectedFormat]);

  const checkSelectSpeciality = (content) => {
    if (content === selectedSpeItem) {
      content = null;
    }
    refreshSpeciality(content);
  };

  const refreshSpeciality = (content) => {
    setSelectedSpeItem(content);
    setIsOpen(true);
    // A SUPPRIMER
    ReactGA.event({
      category: content,
      action: "Clicked the button " + content,
    });
    filterSpecialtyData(
      content,
      selectedSemester,
      selectedInstitution,
      selectedGrade
    );
  };

  useEffect(() => {
    refreshSpeciality(selectedSpeItem);
  }, [selectedSemester, selectedSpeItem, selectedInstitution, selectedGrade]);

  const setLanguageCatalogOpen = () => {
    setSpecialtyOpen(false);
    setLanguageOpen(!languageOpen);
    // Ajoutez d'autres logiques si nécessaire
  };

  const setSpecialtyCatalogOpen = () => {
    setSpecialtyOpen(!specialtyOpen);
    setLanguageOpen(false);
    // Ajoutez d'autres logiques si nécessaire
  };

  const toggle = (index) => {
    const newModals = [...modals];
    newModals[index] = !newModals[index];
    setModals(newModals);
  };

  let Logos = {
    USMB: require("../Image/LogoUniv/usmb.png"),
    UPPA: require("../Image/LogoUniv/uppa.png"),
    UNITO: require("../Image/LogoUniv/unito.png"),
    CHNU: require("../Image/LogoUniv/chnu.png"),
    "HES-SO": require("../Image/LogoUniv/hesso.png"),
    UBI: require("../Image/LogoUniv/ubi.png"),
    UPNA: require("../Image/LogoUniv/upna.png"),
    UNIBS: require("../Image/LogoUniv/unibs.png"),
    UniTBv: require("../Image/LogoUniv/unitbv.png"),
    IPG: require("../Image/LogoUniv/pg.png"),
    UNIZAR: require("../Image/LogoUniv/unizar.png"),
    UVT: require("../Image/LogoUniv/uvt.png"),
    UNITA: require("../Image/LogoUniv/logo.jpg"),
  };

  const getLink = (content) => {
    switch (content) {
      case "French":
        return "https://www.institutfrancais.de/fr/test-de-francais-en-ligne#/";
      case "Italian":
        return "https://www.esl-languages.com/en/online-language-tests/italian-test";
      case "Portuguese":
        return "https://www.classgap.com/tests/en/portuguese/portuguese-test";
      case "Romanian":
        return "https://languagelifeschool.com/en/quiz/test-determine-level-romanian/";
      case "Spanish":
        return "https://www.esl-languages.com/en/online-language-tests/spanish-test";
      case "Intercomprehension":
        return "https://www.univ-smb.fr/2023/03/02/un-programme-intensif-hybride-dedie-a-lintercomprehension-organise-a-lusmb/";
      // Ajoutez d'autres cas selon les valeurs possibles de handleColClick2
      default:
        return "";
    }
  };

  const toggleDropdown1 = () => {
    setDropdownOpen1(!dropdownOpen1);
  };

  const toggleDropdown2 = () => {
    setDropdownOpen2(!dropdownOpen2);
  };

  const toggleDropdown3 = () => {
    setDropdownOpen3(!dropdownOpen3);
  };

  //Implémenter l'appel en fonction du catalogue sélectionné

  const renderLanguageCol = (itemName, imageSrc, altText) => {
    const isSelected = selectedLanItem === itemName;
    const scaleClass = isSelected ? "scale-selected" : "";
    //Mutualiser les onClick
    return (
      <Col
        xs={2}
        className={`custom-col ${scaleClass} `}
        onClick={() => checkSelectLanguage(itemName)}
      >
        <Card className="card-with-transition">
          <CardImg top src={imageSrc} alt={altText} />
        </Card>
      </Col>
    );
  };

  const renderSpecialtyCol = (itemName, imageSrc, altText) => {
    const isSelected = selectedSpeItem === itemName;
    const scaleClass = isSelected ? "scale-selected" : "";
    //Mutualiser les onClick
    return (
      <Col
        xs={2}
        className={`custom-col ${scaleClass} `}
        onClick={() => checkSelectSpeciality(itemName)}
      >
        <Card className="card-with-transition">
          <CardImg top src={imageSrc} alt={altText} />
        </Card>
      </Col>
    );
  };

  const ButtonCard = ({ link }) => (
    <Card className="button-card">
      <CardBody>
        <a href={link} target="_blank" rel="noreferrer">
          <Button style={{ backgroundColor: "#0C2340" }}>
            Test your level in this language
          </Button>
        </a>
      </CardBody>
    </Card>
  );

  const toggleTooltip = () => {
    setTooltipOpen(!TooltipOpen);
  };

  //Gérer le double filterData
  const filterLanguageData = (
    selectedLang,
    selectedSemester,
    selectedLevel,
    selectedFormat
  ) => {
    const filteredLanguageList = responseLanguageData.filter((item) => {
      const langMatch =
        selectedLang === "" || item.LanguageNames === selectedLang;

      // Si selectedLevel est vide, on ne vérifie pas cette condition
      const semesterMatch =
        selectedSemester === "All semesters" ||
        item.SemesterName === selectedSemester;

      const levelMatch =
        selectedLevel === "All levels" ||
        item.LevelNames.split(",").includes(selectedLevel);

      const formatMatch =
        selectedFormat === "All formats" ||
        (selectedFormat === "Asynchronous" && item.SyncHours === 0) ||
        (selectedFormat === "Synchronous" && item.SyncHours > 0);

      const showCourseMatch = item.ShowCourse !== "0";

      return (
        langMatch &&
        semesterMatch &&
        levelMatch &&
        formatMatch &&
        showCourseMatch
      );
    });
    setFilteredLanguageData(filteredLanguageList);
  };

  //Gérer le double FilterData
  const filterSpecialtyData = (
    selectedItem,
    selectedSemester,
    selectedInstitution,
    selectedGrade
  ) => {
    const filteredSpecialtyList = responseSpecialtyData.filter((item) => {
      const domainMatch =
        selectedSemester === null ||
        selectedItem === null ||
        item.MainDomainName === selectedItem;

      const semesterMatch =
        selectedSemester === "All semesters" ||
        item.SemesterName === selectedSemester;

      const institutionMatch =
        selectedInstitution === "All Universities" ||
        item.InstitutionName === selectedInstitution;

      const gradeMatch =
        selectedGrade === "All grades" || item.StudyLevelName === selectedGrade;

      return domainMatch && semesterMatch && institutionMatch && gradeMatch;
    });

    setFilteredSpecialtyData(filteredSpecialtyList);
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  //A fusionner et modifier, return de language catalog :
  return (
    <>
      <Container className="pt-1 pb-1 mb-2">
        <Row>
          <Col>
            <Row className="justify-content-md-center">
              <Col className="order-first col-md-auto">
                <Card
                  className="card-with-transition"
                  style={{ width: "18rem" }}
                >
                  <CardImg
                    top
                    src={Language}
                    alt={"Logo " + Language}
                    className="cardimgg"
                    style={{ width: "18rem" }}
                  />
                  <CardBody
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {languageOpen && (
                      <Button
                        className="btn btn-light btn-customapp bleubg colorWhite"
                        onClick={setLanguageCatalogOpen}
                      >
                        Hide language courses
                      </Button>
                    )}
                    {!languageOpen && (
                      <Button
                        className="btn btn-light btn-customapp bleubg colorWhite"
                        onClick={setLanguageCatalogOpen}
                      >
                        See language courses
                      </Button>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col className="order-second col-md-auto">
                <Card
                  className="card-with-transition"
                  style={{ width: "18rem" }}
                >
                  <CardImg
                    top
                    src={Courses}
                    alt="Logo 1"
                    className="cardimgg"
                    style={{ width: "18rem" }}
                  />
                  <CardBody
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {specialtyOpen && (
                      <Button
                        className="btn btn-light btn-customapp bleubg colorWhite"
                        onClick={setSpecialtyCatalogOpen}
                      >
                        Hide speciality courses
                      </Button>
                    )}
                    {!specialtyOpen && (
                      <Button
                        className="btn btn-light btn-customapp bleubg colorWhite"
                        onClick={setSpecialtyCatalogOpen}
                      >
                        See speciality courses
                      </Button>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col className="justify-content-md-center col-2">
                {languageOpen && (
                  <a
                    className="btn btn-customapp center-content col-button"
                    target="_blank"
                    rel="noreferrer"
                    href="https://univ-unita.eu/Sites/unita/en/Pagina/mobility"
                  >
                    Apply now
                  </a>
                )}
                {specialtyOpen && (
                  <a
                    className="btn btn-customapp center-content col-button"
                    target="_blank"
                    rel="noreferrer"
                    href="https://univ-unita.eu/Sites/unita/en/Pagina/mobility"
                  >
                    Apply now
                  </a>
                )}
              </Col>
            </Row>
            <hr className="separatedLine" />

            {languageOpen && (
              <>
                <Row className="justify-content-between custom-row pt-5">
                  {renderLanguageCol("Spanish", Spanish, "Logo Spanish")}
                  {renderLanguageCol("French", French, "Logo French")}
                  {renderLanguageCol("Italian", Italian, "Logo Italien")}
                </Row>
                <Row className="justify-content-between custom-row pt-5">
                  {renderLanguageCol(
                    "Portuguese",
                    Portuguese,
                    "Logo Portuguese"
                  )}
                  {renderLanguageCol("Romanian", Romanian, "Logo Romanian")}
                  {renderLanguageCol(
                    "Intercomprehension",
                    Intercomprehension,
                    "Logo Intercomprehension"
                  )}
                </Row>
              </>
            )}

            {specialtyOpen && (
              <>
                <Row className="justify-content-between custom-row pt-5 mb-4">
                  {renderSpecialtyCol(
                    "Computer Science",
                    ComputerScience,
                    "Logo ComputerScience"
                  )}
                  {renderSpecialtyCol("Economics", Economics, "Logo Economics")}
                  {renderSpecialtyCol(
                    "Food Science",
                    FoodScience,
                    "Logo FoodScience"
                  )}
                  {renderSpecialtyCol(
                    "Geoscience",
                    Geoscience,
                    "Logo Geoscience"
                  )}
                  {renderSpecialtyCol("Languages", Languages, "Logo Languages")}
                </Row>
                <Row className="justify-content-between custom-row pt-5 mb-4">
                  {renderSpecialtyCol("Law", Law, "Logo Law")}
                  {renderSpecialtyCol(
                    "Literature",
                    Literature,
                    "Logo Literature"
                  )}
                  {renderSpecialtyCol(
                    "Management",
                    Management,
                    "Logo Management"
                  )}
                  {renderSpecialtyCol("Tourism", Tourism, "Logo Tourism")}
                  {renderSpecialtyCol(
                    "Agriculture, forestry, fisheries and veterinary",
                    AnimalScience,
                    "Logo Animal Science"
                  )}
                </Row>
                <Row className="justify-content-between custom-row pt-5 mb-4">
                  {renderSpecialtyCol(
                    "Engineering",
                    Engineering,
                    "Logo Engineering"
                  )}
                  {renderSpecialtyCol(
                    "Mathematics",
                    Mathematics,
                    "Logo Mathematics"
                  )}
                  {renderSpecialtyCol(
                    "Political Science",
                    PoliticalScience,
                    "Logo PoliticalScience"
                  )}
                  {renderSpecialtyCol(
                    "Psychology",
                    Psychology,
                    "Logo Psychology"
                  )}
                  {renderSpecialtyCol("Sports", Sports, "Logo Sports")}
                </Row>
              </>
            )}
          </Col>
        </Row>
        <Row
          style={{
            textAlign: "center",
            marginBottom: "10px",
            marginTop: "20px",
          }}
        ></Row>

        <Row
          style={{
            textAlign: "center",
            marginBottom: "5px",
            marginTop: "1rem",
            paddingLeft: "20rem",
            paddingRight: "20rem",
          }}
        >
          <Col>
            {languageOpen && (
              <Dropdown isOpen={dropdownOpen1} toggle={toggleDropdown1}>
                <DropdownToggle caret>{selectedSemester}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setSelectedSemester("All semesters");
                    }}
                  >
                    All semesters
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedSemester("S1");
                    }}
                  >
                    Semester 1
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedSemester("S2");
                    }}
                  >
                    Semester 2
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
            {specialtyOpen && (
              <Dropdown isOpen={dropdownOpen1} toggle={toggleDropdown1}>
                <DropdownToggle caret>{selectedSemester}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setSelectedSemester("All semesters");
                    }}
                  >
                    All semesters
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedSemester("S1");
                    }}
                  >
                    Semester 1
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedSemester("S2");
                    }}
                  >
                    Semester 2
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </Col>

          <Col>
            {languageOpen && (
              <Dropdown isOpen={dropdownOpen2} toggle={toggleDropdown2}>
                <DropdownToggle caret>{selectedLevel}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setSelectedLevel("All levels");
                    }}
                  >
                    All levels
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedLevel("A1");
                    }}
                  >
                    A1
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedLevel("A1p");
                    }}
                  >
                    A1+
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedLevel("A2");
                    }}
                  >
                    A2
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedLevel("A2p");
                    }}
                  >
                    A2+
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedLevel("B1");
                    }}
                  >
                    B1
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedLevel("B1p");
                    }}
                  >
                    B1+
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedLevel("B2");
                    }}
                  >
                    B2
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedLevel("B2p");
                    }}
                  >
                    B2+
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedLevel("C1");
                    }}
                  >
                    C1
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedLevel("C2");
                    }}
                  >
                    C2
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedLevel("B - Italiano per lo studio");
                    }}
                  >
                    B - Italiano per lo studio
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
            {specialtyOpen && (
              <Dropdown isOpen={dropdownOpen2} toggle={toggleDropdown2}>
                <DropdownToggle caret>{selectedInstitution}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("All Universities");
                      // console.log(SelectedCountry);
                    }}
                  >
                    All Universities
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("UPPA");
                    }}
                  >
                    Université de Pau et des pays de l'Adour
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("USMB");
                    }}
                  >
                    Université Savoie Mont Blanc
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("UVT");
                    }}
                  >
                    Universitatea de Vest din Timisoara
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("UBI");
                    }}
                  >
                    Universidade da Beira Interior
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("UNITO");
                    }}
                  >
                    Università degli Studi di Torino
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("UNIZAR");
                    }}
                  >
                    Universidad de Zaragoza
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("IPG");
                    }}
                  >
                    Instituto Politécnico da Guarda
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("UniTBv");
                    }}
                  >
                    Universitatea Transilvania din Brasov
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("UNIBS");
                    }}
                  >
                    Università degli Studi di Brescia
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("UPNA");
                    }}
                  >
                    Universidad Pública de Navarra
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("HES-SO");
                    }}
                  >
                    Haute École Spécialisée de Suisse Occidentale
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedInstitution("CHNU");
                    }}
                  >
                    Yuriy Fedkovych Chernivtsi National University
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </Col>

          <Col>
            {languageOpen && (
              <Dropdown isOpen={dropdownOpen3} toggle={toggleDropdown3}>
                <DropdownToggle caret>{selectedFormat}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setSelectedFormat("All formats");
                    }}
                  >
                    All formats
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedFormat("Synchronous");
                    }}
                  >
                    Synchronous
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedFormat("Asynchronous");
                    }}
                  >
                    Asynchronous
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
            {specialtyOpen && (
              <Dropdown isOpen={dropdownOpen3} toggle={toggleDropdown3}>
                <DropdownToggle caret>{selectedGrade}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setSelectedGrade("All grades");
                    }}
                  >
                    All grades
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedGrade("Master");
                    }}
                  >
                    Master
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedGrade("Bachelor");
                    }}
                  >
                    Bachelor
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedGrade("Bachelor/Master");
                    }}
                  >
                    Bachelor/Master
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </Col>
        </Row>

        <Row style={{ textAlign: "center" }}></Row>

        {isOpen2 && languageOpen && (
          <>
            <div className="table-container22" ref={tableRef}>
              <div className="table-responsive">
                <table className="listing">
                  <thead>
                    <tr>
                      <th>Institution</th>
                      <th>Country</th>
                      <th>Level</th>
                      <th>Semester</th>
                      <th>Days of week</th>
                      <th>
                        <Button
                          className="button"
                          outline
                          size=""
                          id="infobulle"
                        >
                          TimeSlot (Central European Time CET)
                        </Button>
                        <div>
                          <Tooltip
                            placement="top"
                            isOpen={TooltipOpen}
                            target="infobulle"
                            toggle={toggleTooltip}
                          >
                            Pay attention to the time zone differences:
                            <br />
                            - Portugal operate on UTC 0.
                            <br />
                            - Spain, France, Italy, Switzerland: UTC +1, which
                            align with Central European Time
                            <br />
                            - Romania and Ukraine follow UTC +2
                            <br />
                          </Tooltip>
                        </div>
                      </th>
                      <th>Date</th>
                      <th>Lecture hours in synchronous mode</th>
                      <th>Lecture hours in asynchronous mode</th>
                      <th>Number of ECTS</th>
                      <th>Number of places</th>
                      <th>See More</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredLanguageData == "" && (
                      <React.Fragment key="0">
                        <tr className="bordertoop">
                          <td colspan="12" className="flash">
                            No courses actually available
                          </td>
                        </tr>
                      </React.Fragment>
                    )}
                    {filteredLanguageData.map((item, index) => (
                      <React.Fragment key={item.id}>
                        <tr className="bordertoop">
                          <td>
                            {item.InstitutionFullName} ({item.InstitutionName})
                          </td>
                          <td>{item.InstitutionCountry}</td>
                          <td>
                            <Container fluid>
                              <Row>
                                {item.LevelNames.split(",").map((level) => (
                                  <Col sm="6">
                                    <div className="logo-container2">
                                      <div className="logo-wrapper">
                                        <div className="image-wrap">
                                          <img
                                            className="small-icon"
                                            src={getImageByName(level)}
                                            alt={"Logo " + level}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </Container>
                          </td>
                          <td>
                            <Container fluid>
                              <Row>
                                <Col sm="6">
                                  <div className="logo-container2">
                                    <div className="logo-wrapper">
                                      <img
                                        className="small-icon"
                                        src={getImageByName(item.SemesterName)}
                                        alt={item.SemesterName}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </td>
                          <td>{item.DayOfWeekNames}</td>
                          <td>{item.TimeSlotNames}</td>
                          <td>{item.DateName}</td>
                          <td>{item.SyncHours}</td>
                          <td>{item.AsyncHours}</td>
                          <td>{item.ECTS}</td>
                          <td>{item.MaxPlaces}</td>
                          {
                            // BOUTON DE LA MODAL D'INFORMATION
                            <td>
                              <Button
                                color="light"
                                onClick={() => toggle(index)}
                              >
                                <div>
                                  <FontAwesomeIcon icon={faEye} />
                                </div>
                              </Button>
                            </td>
                          }
                        </tr>

                        {
                          <Modal
                            isOpen={modals[index]}
                            toggle={() => toggle(index)}
                            size="xl"
                            backdrop={true}
                            fade={true}
                            centered={true}
                            scrollable={false}
                          >
                            <ModalHeader toggle={() => toggle(index)}>
                              {item.LanguageNames}{" "}
                              {" (" + item.InstitutionName + ")"}
                            </ModalHeader>
                            <ModalBody>
                              <Row>
                                <Col xs={12} md={2} className="custom-col ">
                                  <Card className="card-with-transition ">
                                    <CardImg top src={Logos["UNITA"]} />
                                  </Card>
                                  <Card className="card-with-transition">
                                    <CardImg
                                      top
                                      src={getImageByName(item.MainDomainName)}
                                    />
                                  </Card>
                                  <Card className="card-with-transition pt-5">
                                    <CardImg
                                      top
                                      src={Logos[item.InstitutionName]}
                                    />
                                  </Card>
                                </Col>

                                <Col>
                                  <Row>
                                    <Col md="6">
                                      <p>
                                        Language (Level):{" "}
                                        <strong>
                                          {item.LanguageNames} (
                                          {item.LevelNames})
                                        </strong>
                                      </p>
                                      {item.SemesterName && (
                                        <p>
                                          Semester :{" "}
                                          <strong>{item.SemesterName}</strong>
                                        </p>
                                      )}
                                      <p>{item.CourseDescription}</p>

                                      {item.CourseDescriptionLink && (
                                        <p>
                                          <a
                                            className=" btn astylyingg"
                                            target="_blank"
                                            rel="noreferrer"
                                            href={item.CourseDescriptionLink}
                                          >
                                            Click here for more details
                                          </a>
                                        </p>
                                      )}
                                    </Col>
                                    <Col md="6">
                                      {item.CourseContactName && (
                                        <p>
                                          Person to contact :{" "}
                                          <strong>
                                            {item.CourseContactName}
                                          </strong>
                                        </p>
                                      )}
                                      {item.CourseContactEmail && (
                                        <p>
                                          Email adress :{" "}
                                          <strong>
                                            {item.CourseContactEmail}
                                          </strong>
                                        </p>
                                      )}
                                      {item.DayOfWeekNames && (
                                        <p>
                                          Day of week the training course
                                          happens :{" "}
                                          <strong>{item.DayOfWeekNames}</strong>
                                        </p>
                                      )}
                                      {item.TimeSlotName && (
                                        <p>
                                          Time slot of the training course (CET)
                                          : <strong>{item.TimeSlotName}</strong>
                                        </p>
                                      )}
                                      {item.NumAssessments && (
                                        <p>
                                          Num. of Assessments :{" "}
                                          <strong>{item.NumAssessments}</strong>
                                        </p>
                                      )}
                                      {item.AssessmentMethodReg && (
                                        <p>
                                          Assessment method regular session :{" "}
                                          <strong>
                                            {item.AssessmentMethodReg}
                                          </strong>
                                        </p>
                                      )}
                                      {item.AssessmentMethodCat && (
                                        <p>
                                          Assessment method catch up session :{" "}
                                          <strong>
                                            {item.AssessmentMethodCat}
                                          </strong>
                                        </p>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Table
                                      bordered
                                      responsive
                                      className="table-bordered-black listing"
                                    >
                                      <thead class="display:block">
                                        <tr>
                                          <th>ECTS</th>
                                          <th>Min. Places</th>
                                          <th>Max. Places</th>
                                          <th>Synchronous hours</th>
                                          <th>Asynchronous hours</th>
                                          <th>Teaching volume in hours</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th scope="row">
                                            <strong>{item.ECTS}</strong>
                                          </th>
                                          <td className="small-number">
                                            <strong> {item.MinPlaces}</strong>
                                          </td>
                                          <td className="small-number">
                                            <strong>{item.MaxPlaces}</strong>
                                          </td>
                                          <td className="small-number">
                                            {" "}
                                            <strong> {item.SyncHours}</strong>
                                          </td>
                                          <td className="small-number">
                                            {" "}
                                            <strong> {item.AsyncHours}</strong>
                                          </td>
                                          <td className="small-number">
                                            {" "}
                                            <strong>
                                              {" "}
                                              {item.TeachingVolHours}
                                            </strong>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </Row>
                                </Col>
                              </Row>
                            </ModalBody>
                            <ModalFooter>
                              <div className="containerpage">
                                2023 ©
                                <a
                                  href="https://univ-unita.eu/Sites/unita/en/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  unita - universitas montium
                                </a>
                                {item.CourseContactEmail ===
                                  "andreeabratan@unitbv.ro" && (
                                    <a
                                    href="/UNITBV_Romanian_Civ_UNITA_Virtual_Mobility.pdf"
                                    download
                                    className="btn btn-info"
                                    style={{ marginRight: "auto" }}
                                  >
                                    Download the PDF
                                  </a>
                                )}
                              </div>
                            </ModalFooter>
                          </Modal>
                        }
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {isOpen && specialtyOpen && (
          <>
            <div className="table-container22" ref={tableRef}>
              {/* <h3>Table Content</h3> */}
              <div className="table-responsive">
                <table className="listing">
                  <thead>
                    <tr>
                      <th>Institution</th>

                      <th>Main Domain</th>
                      <th>Course Title</th>
                      <th>Level</th>
                      <th>Semester</th>
                      <th>Number of ECTS</th>
                      <th>
                        <Button
                          className="button"
                          outline
                          size=""
                          id="infobulle"
                        >
                          Teaching modality
                        </Button>
                        <div>
                          <Tooltip
                            placement="top"
                            isOpen={TooltipOpen}
                            target="infobulle"
                            toggle={toggleTooltip}
                          >
                            Teaching modalities can be categorized as:
                            <br />
                            - Synchronous (Simultaneous)
                            <br />
                            - Asynchronous
                            <br />- Hybrid (Combining both modalities).
                          </Tooltip>
                        </div>
                      </th>
                      <th>See More</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSpecialtyData == "" && (
                      <React.Fragment key="0">
                        <tr className="bordertoop">
                          <td colspan="8" className="flash">
                            No courses actually available
                          </td>
                        </tr>
                      </React.Fragment>
                    )}
                    {filteredSpecialtyData.map((item, index) => (
                      <React.Fragment key={item.id}>
                        <tr
                          className="bordertoop"
                          onClick={() => toggle(index)}
                        >
                          <td>
                            {item.InstitutionFullName} ({item.InstitutionName})
                          </td>
                          <td>{item.MainDomainName}</td>
                          <td>{item.CourseTitle}</td>
                          <td>{item.StudyLevelName}</td>
                          <td>{item.SemesterName}</td>
                          <td>{item.Ects}</td>
                          <td>{item.Modality}</td>
                          <td>
                            <Button color="light" onClick={() => toggle(index)}>
                              <div>
                                <FontAwesomeIcon icon={faEye} />
                              </div>
                            </Button>
                          </td>
                        </tr>

                        <Modal
                          isOpen={modals[index]}
                          toggle={() => toggle(index)}
                          size="xl"
                          backdrop={true}
                          fade={true}
                          centered={true}
                          scrollable={false}
                        >
                          {/* modal content */}
                          <ModalHeader toggle={() => toggle(index)}>
                            {item.CourseTitle}{" "}
                            {" (" + item.InstitutionName + ")"}
                          </ModalHeader>
                          <ModalBody>
                            <Row>
                              <Col xs={12} md={2} className="custom-col ">
                                <Card className="card-with-transition ">
                                  <CardImg top src={Logos["UNITA"]} />
                                </Card>
                                <Card className="card-with-transition">
                                  <CardImg
                                    top
                                    src={getImageByName(item.MainDomainName)}
                                  />
                                </Card>
                                <Card className="card-with-transition pt-5">
                                  <CardImg
                                    top
                                    src={Logos[item.InstitutionName]}
                                  />
                                </Card>
                              </Col>
                              <Col>
                                <Row>
                                  <Col md="6">
                                    <p>
                                      Main Domain :{" "}
                                      <strong>
                                        {item.MainDomainName} (
                                        {item.StudyLevelName})
                                      </strong>
                                    </p>
                                    <p>
                                      Disciplinary fields :{" "}
                                      <strong>
                                        {item.DisciplinaryFieldName}
                                      </strong>
                                    </p>
                                    <p>
                                      Language (Level):{" "}
                                      <strong>
                                        {item.LanguageName} (
                                        {item.LanguageLevelName})
                                      </strong>
                                    </p>
                                    <p>
                                      Semester :{" "}
                                      <strong>
                                        {item.SemesterName +
                                          " (From " +
                                          item.TeachingPeriodStart +
                                          " till " +
                                          item.TeachingPeriodEnd +
                                          ")"}
                                      </strong>
                                    </p>

                                    <p>{item.CourseDescription}</p>
                                    {item.CourseDescriptionLink && (
                                      <p>
                                        <a
                                          className=" btn astylyingg"
                                          target="_blank"
                                          rel="noreferrer"
                                          href={item.CourseDescriptionLink}
                                        >
                                          Click here for more details
                                        </a>
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="6">
                                    {item.Prerequisites && (
                                      <p>
                                        Prerequisites :{" "}
                                        <strong>{item.Prerequisites}</strong>
                                      </p>
                                    )}
                                    {item.DayOfWeekNames && (
                                      <p>
                                        Day of week the training course happens
                                        : <strong>{item.DayOfWeekNames}</strong>
                                      </p>
                                    )}
                                    {item.TimeSlotName && (
                                      <p>
                                        Time slot of the training course (CET) :{" "}
                                        <strong>{item.TimeSlotName}</strong>
                                      </p>
                                    )}
                                    {item.NumAssessments && (
                                      <p>
                                        Num. of Assessments :{" "}
                                        <strong>{item.NumAssessments}</strong>
                                      </p>
                                    )}
                                    {item.AssessmentMethodReg && (
                                      <p>
                                        Assessment method regular session :{" "}
                                        <strong>
                                          {item.AssessmentMethodReg}
                                        </strong>
                                      </p>
                                    )}
                                    {item.AssessmentMethodCat && (
                                      <p>
                                        Assessment method catch up session :{" "}
                                        <strong>
                                          {item.AssessmentMethodCat}
                                        </strong>
                                      </p>
                                    )}
                                    {item.OpenStudyCycle && (
                                      <p>
                                        Open study cycle :{" "}
                                        <strong>{item.OpenStudyCycle}</strong>
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Table
                                    bordered
                                    responsive
                                    className="table-bordered-black"
                                  >
                                    <thead class="display: block">
                                      <tr>
                                        <th>ECTS</th>
                                        <th>Min. Places</th>
                                        <th>Max. Places</th>
                                        <th>Teaching modality</th>
                                        <th>Teaching volume in hours</th>
                                        {item.ExamPeriod && (
                                          <th>Exam Period</th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">
                                          <strong>{item.Ects}</strong>
                                        </th>
                                        <td className="small-number">
                                          <strong> {item.MinPlaces}</strong>
                                        </td>
                                        <td className="small-number">
                                          <strong>{item.MaxPlaces}</strong>
                                        </td>
                                        <td className="small-number">
                                          <strong>{item.Modality}</strong>
                                        </td>
                                        <td className="small-number">
                                          {" "}
                                          <strong>
                                            {" "}
                                            {item.TeachingVolHours}
                                          </strong>
                                        </td>
                                        {item.ExamPeriod && (
                                          <td className="small-number">
                                            <strong> {item.ExamPeriod}</strong>
                                          </td>
                                        )}
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Row>
                              </Col>
                            </Row>
                          </ModalBody>
                          <ModalFooter>
                            <div className="containerpage">
                              2023 ©
                              <a
                                href="https://univ-unita.eu/Sites/unita/en/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                unita - universitas montium
                              </a>
                            </div>
                          </ModalFooter>
                        </Modal>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {isOpen2 && languageOpen && selectedLanguage !== "" && (
          <Row
            style={{
              textAlign: "center",
              marginBottom: "5px",
              marginTop: "20px",
            }}
          >
            <div className="button-card">
              <ButtonCard link={getLink(selectedLanguage)} />
            </div>
          </Row>
        )}

        <style>
          {`
            .table-container22 {
              margin-top: 5rem;
              border-radius: 35px;
              background-color: white;
            }
    
            .table-container22 table {
              width: 100%;
              border-collapse: collapse;
            }
    
            .table-container22 th,
            .table-container22 td {
              padding: 0.5rem;
              text-align: center;
              border : none;
              // border: 1px solid #ccc;
              color:black;
            }
    
            .close-button22 {
              margin-top: 5rem;
              padding: 0.5rem 1rem;
              color:white;
              background-color: #0C2340;
              border: none;
              cursor: pointer;
            }

            .button-card {
              display: flex;
              justify-content: center;
              align-items: center;
            }
      `}
        </style>
      </Container>
      <div>{/* Use responseData in your component */}</div>
    </>
  );
}

export default DisplayCatalog;
